<template>
	<!-- 解决方案-需求记录单 -->
	<div class="container">
		<GModule>
			<div class="condition-box">
				<div class="state-box">
					<p style="width: 60px;">创建时间</p>
					<a-range-picker
					    style="width: 400px"
					    :ranges="ranges"
					    show-time
					    format="YYYY-MM-DD HH:mm:ss"
						@change="change"/>
				</div>
				<div>
					<a-button class="btn clo3" @click="search()">查询</a-button>
					<a-button class="btn clo1" @click="openRecord()">添加</a-button>
					<a-button class="btn clo2">导出</a-button>
				</div>
			</div>
			<a-table
				class="ttable" 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500,y:450 }" 
				bordered
				:pagination="false">
				<template #bodyCell="{ column ,record,index}">
					<template v-if="column.dataIndex === 'user'">
						{{record.user.nickname}}
					</template>
					<template v-if="column.title === '需求描述'">
					  	<a-popover placement="bottom">
					  	    <template #content>
					  	       <p style="max-width: 500px;">{{ record.describe }}</p>
					  	    </template>
							 <p class="overtext">{{ record.describe }}</p>
					  	  </a-popover>
					</template>
				    <template v-if="column.key === 'operation'">
						<div class="caozuo">
							<p @click="updateBtn(record.id,record.describe)">修改</p>
							<a-popconfirm
							    title="确认是否删除?"
							    ok-text="确认"
							    cancel-text="取消"
							    @confirm="delBtn(record.id,index)">
							    <p>删除</p>
							</a-popconfirm>
						</div>
					</template>
				  </template>
			</a-table>
			<div class="documentFt" style="margin-top: 10px;">
			  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
			</div>
		</GModule>
	</div>
</template>

<script>
import dayjs from 'dayjs';
export default{
	data(){
		return{
			columnsHe: [{
			    title: '创建时间',
			    width: 100,
			    dataIndex: 'createtime_text',
			    fixed: 'left'
			  }, {
			    title: '解决方案经理',
			    width: 80,
			    dataIndex: 'user'
			  }, {
			    title: '需求描述',
			    width: 300,
			    dataIndex: 'describe'
			  }, {
			    title: '状态',
			    width: 80,
			    dataIndex: 'status_text'
			  }, {
			    title: '操作',
			    width: 80,
			    dataIndex: 'operation',
				key:'operation'
			}],
			data: [],
			limit:10,
			page:1,
			createtime:'' ,// 创建时间
			ranges: {
			  	'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
			  	'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
			  	'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
			  	'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
			},
			total:0 ,// 总条数
		}
	},
	computed:{
		isFresh(){
			return this.$store.state.isFresh
		},
		updateId(){
			return this.$store.state.updateId
		},
		isUpdateFresh(){
			return this.$store.state.isUpdateFresh
		}
	},
	watch:{
		isFresh(val,oldvalue){
			// 添加后刷新数据
			if(val){
				// 重置数据，刷新列表
				this.page = 1 
				this.createtime = ''
				this.$store.commit('setIsFresh',0)
				this.getList()
			}
		},
		isUpdateFresh(val,oldvalue){
			// 修改完成后回显列表
			if(val){
				this.getList()
				this.$store.commit('setUpdateId',null) // 重置Id
				this.$store.commit('setUpdateDescribe',"") //重置需求描述
			}
		}
	},
	created(){
		this.getList()
	},
	methods:{
		openRecord(){
			this.$store.commit('setIsRecordAdd',1)
		},
		change(date,value){
			// 选择创建时间
			if(value[0] == '' || value[1] == ''){
				// 创建时间要同时满足开始时间和结束时间才可以查询
				this.createtime = ''
				return
			}
			let val = value.join(' - ') // 拼接日期
			this.createtime = val
		},
		pageChange(page,pageSize){
			// 切换页码时查询列表
			this.page = page
			this.getList()
		},
		search(){
			// 根据条件查询列表
			this.page = 1 //每次点击查询按钮都要重置页码为1
			this.getList()
		},
		async getList(){
			// 获取列表
			let info = {
				limit:this.limit,
				page:this.page,
				op:{},
				filter:{}
			}
			if(this.createtime){
				info.op['createtime'] = 'RANGE'
				info.filter['createtime'] = this.createtime
			}
			let res = await this.$utils.api.post({
				url:'/demand_record/index',
				data:info,
				result:1
			})
			if(res.code == 1){
				this.data = res.data.rows
				this.total = res.data.total
			}
		},
		async delBtn(id,index){
			// 删除
			let res = await this.$utils.api.post({
				url:'/demand_record/del',
				data:{
					ids:id
				},
				result:1
			})
			if(res.code == 1){
				this.$utils.msgSuc('删除成功')
				this.data.splice(index,1)
			}
		},
		updateBtn(id,describe){
			// 编辑
			this.$store.commit('setIsRecordAdd',1) //弹出修改
			this.$store.commit('setUpdateId',id) // 修改Id
			this.$store.commit('setUpdateDescribe',describe) //回显
		},
	}
}
</script>

<style scoped lang="scss">
.container{
	margin: 20px;
	padding: 0;
	overflow: hidden;
	.condition-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 5px 0 10px 0;
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
			p{
				margin: 0 10px 0 20px;
			}
		}
		.btn{
			margin-left: 20px;
			margin-bottom: 5px;
		}
	}
	.caozuo{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		p{
			margin: 0;
			font-size: 12px;
			color: #407cff;
			margin-left: 5px;
		}
	}
	.overtext{
		display: -webkit-box;
		word-break: break-all;
		text-overflow:ellipsis;
		overflow: hidden;
		white-space: pre-line;
		-webkit-box-orient: vertical;
		-webkit-line-clamp:2;
	}
}
</style>